import styles from "./App.module.css";

function Avatar() {
  return <div className={styles.avatar} />;
}

function Information() {
  return <div className={styles.information}>hi, I&apos;m John</div>;
}

function App() {
  return (
    <div className={styles.container}>
      <Avatar />
      <Information />
    </div>
  );
}

export default App;
